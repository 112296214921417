/* Footer.css */
.footer-container {
  background-color: black;
  color: #fff;
  padding: 20px 0;
  font-size: 15px;
}

.social-media {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}


.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;  /* Ensure it takes full width */
  padding: 0 20px; /* Add padding if needed to push content to the edges */
}


.social-logo img {
  height: 60px; /* Adjust based on your logo's size */
  width: auto;
}


.website-rights {
  flex: 1;  /* Take up necessary space */
  text-align: right; /* Align text to the right */
}

.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icon-link {
  color: #fff;
  font-size: 24px;
  margin: 0 15px;
  transition: color 0.3s;
}

.social-icon-link:hover {
  color: #f39c12; /* Change the hover color as needed */
}

/* Font Awesome Icons adjustment */
.fa-facebook-f, .fa-instagram, .fa-youtube, .fa-twitter, .fa-linkedin {
  margin-right: -10px; /* Adjust based on actual icon alignment */
}
