* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}
.image {
  margin: 20px 20px 20px 20px;
}
.description {
  margin: 20px 20px 20px 20px;
}

.about{
  display: flex;
  flex-flow: column;
  min-height: 100vh;
  padding-bottom: 100px;
  width: 100%;
}
.about h1 {
  margin: 10px 10px 10px 10px;
  padding: 5px 5px 5px 5px;
}
.about p {
  /* Adjust paragraph styling as needed */
  /* For example, you can set margin, padding, font-size, etc. */
  margin: 0;
  padding: 10px; /* Example padding */
  font-size: 16px; /* Example font size */
}.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  padding-bottom: 50px;
}

.about h1 {
  margin: 20px;
  padding: 10px;
  text-align: center;
  font-size: 2rem; /* responsive font size */
}

.about p {
  margin: 20px;
  padding: 10px;
  font-size: 1rem;
  line-height: 1.6;
  text-align: justify;
  max-width: 800px; /* optimal line length for reading */
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .about h1 {
    font-size: 1.5rem;
  }

  .about p {
    font-size: 0.9rem;
  }
}
.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  padding: 50px;
  background: #f4f4f9; /* subtle background color */
}

.contact h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
}

.contact-section {
  background: white;
  box-shadow: 0 2px 15px rgba(0,0,0,0.1);
  border-radius: 10px;
  width: 80%;
  max-width: 600px;
  margin-bottom: 20px;
  padding: 20px;
  text-align: left;
}

.contact h4 {
  /* color: #0056b3; */
  margin-bottom: 10px;
}

.contact ul {
  list-style: none;
  padding: 0;
}

.contact li {
  margin-bottom: 5px;
  font-size: 1.1rem;
  color: #666;
}

.contact a {
  color: #0056b3;
  text-decoration: none;
}

.contact a:hover {
  text-decoration: underline;
}

.quote {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box; /* include padding in width calculation */
}

.quote h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.quoteForm {
  width: 100%;
  max-width: 600px; /* Max form width */
  display: flex;
  flex-direction: column;
  gap: 10px; /* spacing between form elements */
}

.quoteForm label {
  font-weight: bold;
}

.quoteForm input[type="text"],
.quoteForm input[type="email"],
.quoteForm input[type="file"],
.quoteForm button {
  padding: 10px;
  margin-top: 2px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.quoteForm button {
  background-color: #0056b3;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.quoteForm button:hover {
  background-color: #004494;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .quote h2 {
    font-size: 20px;
  }

  .quoteForm {
    padding: 10px;
  }
}

.field {
  width: 80%;
  margin: 20px 20px 20px 20px;
  padding: 5px 5px 5px 5px;
}
@media (max-width: 800px) {
  .details {
    flex-direction: column;
  }
}