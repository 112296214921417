.navbar {
  background: black;
  height: 90px; /* Reduced height for better proportion */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem; /* Adjusted for better scaling on devices */
  position: sticky;
  top: 0;
  z-index: 999;
}

.logo {
  height: 60px; /* Adjusted size */
  width: auto; /* Maintain aspect ratio */
  margin: 0 15px; /* Reduced margin */
}

.navbar-container {
  display: flex;
  width: 100%; /* Utilize full width for content spacing */
  max-width: 1500px; /* Max container width */
  justify-content: space-between; /* Space between logo and menu */
  align-items: center;
}

.nav-menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center; /* Align links vertically */
}

.nav-item {
  padding: 10px 15px; /* Uniform padding for clickable area */
}

.nav-links {
  color: #fff;
  text-decoration: none;
  padding: 8px 12px; /* Adjusted padding */
  display: block;
}

.nav-links:hover {
  background-color: #444; /* Subtle hover effect */
  transition: background-color 0.3s;
}

.menu-icon {
  display: none;
}

/* Responsive styles */
@media screen and (max-width: 960px) {
  .nav-menu {
    flex-direction: column;
    position: absolute;
    top: 80px; /* Adjusted to height of the navbar */
    left: 0;
    background: #242222;
    width: 100%;
    height: calc(100vh - 80px); /* Full height minus navbar */
    align-items: center;
    justify-content: start;
    padding-top: 20px;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
  }

  .nav-menu.active {
    transform: translateX(0); /* Move into view */
  }

  .nav-links {
    width: 100%;
    padding: 15px; /* Larger tap targets */
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    font-size: 1.8rem;
    margin: 20px;
  }

  .fa-bars, .fa-times {
    color: #fff;
  }
}
